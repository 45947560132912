import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  font-family: "Space Grotesk", monospace;
  text-decoration: none;
  color: #ccc;
  transition: 0.1s ease-in;
  line-height: 1;
  &:hover {
    color: #fff;
  }
`
const TextLink = styled(Link)`
  font-family: "Space Grotesk", monospace;
  color: #fff;
  text-decoration: none;
`

export const BackToLab = () => {
  return <TextLink to="/lab">Back</TextLink>
}
export const BackToBlog = () => {
  return <TextLink to="/blogs">Back</TextLink>
}
export const BlogLink = ({ children }) => (
  <StyledLink
    to="/blogs"
    partiallyActive={true}
    activeStyle={{ color: "#fff", borderBottom: "3px dashed #fff" }}
  >
    {children || "Blog"}
  </StyledLink>
)
export const About = () => (
  <StyledLink
    to="/"
    activeStyle={{ color: "#fff", borderBottom: "3px dashed #fff" }}
  >
    About
  </StyledLink>
)
