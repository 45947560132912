import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout, { ContentWrapper } from "../../components/layout"
import BlogHeader from "../../components/BlogHeader"
import { BackToBlog } from "../../components/link"
import RichText from "../../components/RichText"
import DateLabel from "../../components/DateLabel"

const Title = styled.h1`
  font-family: "Outfit", sans-serif;
  font-size: 42px;
`
export const BlogTemplate = ({ data, location }) => {
  if (!data) return null
  const document = data.prismicBlog
  return (
    <Layout location={location}>
      <SEO title={document.data.title.text} />
      <BlogHeader>
        <BackToBlog />
      </BlogHeader>
      <ContentWrapper>
        <Title>{document.data.title.text}</Title>
        <DateLabel date={document.data.create_date} />
        <RichText raw={document.data.content.raw} />
      </ContentWrapper>
    </Layout>
  )
}

export const query = graphql`
  query Blog($id: String) {
    prismicBlog(id: { eq: $id }) {
      data {
        create_date
        title {
          text
        }
        content {
          raw
          html
        }
      }
    }
  }
`

export default BlogTemplate
