import React from "react"
import { RichText as PrismicRichText } from "prismic-reactjs"
import { Elements } from "prismic-richtext"

import styled from "styled-components"

const Wrapper = styled.div`
  font-family: "Outfit", sans-serif;
  padding: 20px 0;
  font-size: 20px;
  line-height: 1.5;
  h1,
  h2,
  h3 {
    font-family: "Outfit", sans-serif;
  }
  a {
    text-decoration: none;
    color: #444;
    padding: 0 5px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
`
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.embed: {
      if (element.oembed?.type === "photo") {
        return (
          <img key={key} src={element.oembed.url} alt={element.alt || ""} />
        )
      }
      return null
    }
    case Elements.image:
      return <img key={key} src={element.url} alt={element.alt || ""} />
    default:
      return null
  }
}

const RichText = ({ raw }) => {
  return (
    <Wrapper>
      <PrismicRichText render={raw} htmlSerializer={htmlSerializer} />
    </Wrapper>
  )
}

export default RichText
